import {useState, useEffect} from 'react'
import { Button, Input } from '@windmill/react-ui';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { InformationCircleIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import Spinner from '../Buttons/Spinner';
import Loader from '../Buttons/Loader';
import BillingForm from '../../archive/billing/BillingForm';
import store from '../../store';
import billingSlice from '../../store/slices/billing';
import teamSlice from '../../store/slices/team';
import axios from 'axios';
import toast from 'react-hot-toast';
import Tooltip from '../Tooltip/Tooltip';







const BillingPlanCard = (props) => {
    
    const BACKEND_URL = process.env.REACT_APP_SERVER_URL || window.env.REACT_APP_SERVER_URL;
    const account_url = `${BACKEND_URL}/v1/auth/account`
    const setup_url = `${BACKEND_URL}/v1/billing/subscription/setup`
    const get_sub_url = `${BACKEND_URL}/v1/billing/subscription/url`
    const info_url = `${BACKEND_URL}/v1/billing/info`
    const strip_key_url = `${BACKEND_URL}/v1/billing/stripe/key`
    const coupon_url = `${BACKEND_URL}/v1/billing/coupon/search`
    const token = store.getState().auth.token
    const headers = {'Authorization': `Bearer ${token}`}
    const configs = {headers: headers, skipAuthRefresh: true}


    const [key, setKey] = useState(null);
    const [info, setInfo] = useState(null);
    const [interval, setInterval] = useState('month');
    const [plan, setPlan] = useState('free');
    const [priceAmount, setPriceAmount] = useState(null);
    const [taskAmount, setTaskAmount] = useState(null);
    const [sites, setSites] = useState(1);
    const [pages, setPages] = useState(3);
    const [schedules, setSchedules] = useState(1);
    const [retentionDays, setRetentionDays] = useState(15);
    const [caseruns, setCaseRuns] = useState(15);
    const [flowruns, setFlowRuns] = useState(5);
    const [nodes, setNodes] = useState(6);
    const [conditions, setConditions] = useState(2);
    const [scans, setScans] = useState(30);
    const [tests, setTests] = useState(30);
    const [coupon, setCoupon] = useState('');
    const [discount, setDiscount] = useState(0);
    const [viewCoupon, setViewCoupon] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageloading, setPageloading] = useState(true);
    const [type, setType] = useState('initial');
    const [showFree, setShowFree] = useState(false);
    const [showCloud, setShowCloud] = useState(false);
    const [showSelfHost, setShowSelfHost] = useState(false);
    const [subUrl, setSubUrl] = useState(null);


    // billing defaults
    const setupData = {
        'name': plan, 
        'interval': interval,
        'price_amount': priceAmount, 
        'task_amount': taskAmount, 
        'sites_allowed': sites, 
        'pages_allowed': pages,
        'schedules_allowed': schedules,
        'retention_days': retentionDays,
        'scans_allowed': scans,
        'tests_allowed': tests,
        'caseruns_allowed': caseruns,
        'flowruns_allowed': flowruns,
        'nodes_allowed': nodes,
        'conditions_allowed': conditions,
        'meta': {
            'coupon': {
                'code': coupon,
                'discount': discount
            }
        }
        
    }
   
    // get current billing info
    useEffect(() => {
        preLoad()
    },[])

    // load stripe key
    useEffect(() => {
        getKey()
    },[])


    const getKey = async () => {
        setKey(null)
        await axios.post(strip_key_url, null, configs)
            .then((r) => {
                setKey(r.data.key);
            }).catch((err) => {
                console.log(err);
            });
    }


    const preLoad = async () => {
        await axios.post(info_url, null, configs)
            .then((r) => {
                setInfo(r.data)
                store.dispatch(billingSlice.actions.setBilling(r.data))
                setInterval(r.data.plan.interval)
                if (r.data.plan.name === 'enterprise'){
                    console.log(r.data)
                    setPlan(r.data.plan.name)
                    setPriceAmount(r.data.plan.price_amount)
                    setPages(r.data.plan.usage.pages_allowed)
                    setSites(r.data.plan.usage.sites_allowed)
                    setSchedules(r.data.plan.usage.schedules_allowed)
                    setRetentionDays(r.data.plan.usage.retention_days)
                    setScans(r.data.plan.usage.scans_allowed)
                    setTests(r.data.plan.usage.tests_allowed)
                    setCaseRuns(r.data.plan.usage.caseruns_allowed)
                    setFlowRuns(r.data.plan.usage.flowruns_allowed)
                    setNodes(r.data.plan.usage.nodes_allowed)
                    setConditions(r.data.plan.usage.conditions_allowed)
                    if (r.data.plan.meta){
                        if (r.data.plan.meta.coupon){
                            setCoupon(r.data.plan.meta.coupon.code)
                            checkCoupon(r.data.plan.meta.coupon.code)
                        }
                    }
                    setPageloading(false);
                    
                    // get subUrl if plan.name == enterprise
                    if (r.data.plan.name === 'enterprise'){
                        axios.get(get_sub_url, configs)
                            .then((r) => {
                                console.log(r.data)
                                setSubUrl(r.data.stripe_url)
                                setPageloading(false)
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                    
                } else {
                    setBillingInfo(r.data.plan.name)
                    if (r.data.plan.meta){
                        if (r.data.plan.meta.coupon){
                            setCoupon(r.data.plan.meta.coupon.code)
                            checkCoupon(r.data.plan.meta.coupon.code)
                        }
                    }
                    setPageloading(false)
                }
  
            })
            .catch((e) => {
                console.log(e)
            })

    }


    const setBillingInfo = (p) => {

        switch (p) {
          case 'free':
            setPlan('free')
            setPriceAmount(0)
            setPages(3)
            setSites(1)
            setSchedules(1)
            setCaseRuns(15)
            setFlowRuns(5)
            setNodes(4)
            setConditions(1)
            setScans(30)
            setTests(30)
            setRetentionDays(15)
            break
          case 'cloud':
            setPlan('cloud')
            setPriceAmount(Math.round(1900-(1900*discount)))
            setTaskAmount(Math.round(10-(10*discount)))
            setPages(10)
            setSites(30)
            setSchedules(10)
            setCaseRuns(15)
            setFlowRuns(5)
            setNodes(20)
            setConditions(6)
            setScans(30)
            setTests(30)
            setRetentionDays(180)
            break
           case 'selfhost':
            setPlan('selfhost')
            setPriceAmount(interval === 'month' ? Math.round(100000-(100000*discount)) : Math.round((100000*10)-((100000*10)*discount)))
            setPages(0)
            setSites(0)
            setSchedules(0)
            setCaseRuns(0)
            setFlowRuns(0)
            setNodes(0)
            setConditions(0)
            setScans(0)
            setTests(0)
            setRetentionDays(0)
            break
          case 'enterprise':
            setPlan('enterprise')
            break
        }
    }
    
    
    const checkCoupon = (c, announce=false) => {

        axios.get(`${coupon_url}?code=${c}`)
            .then((r) => {
                if (r.data.success){
            
                    // setting discount
                    setDiscount(r.data.coupon.discount);

                    // setting coupon code
                    setCoupon(r.data.coupon.code);

                    if (announce){
                        toast.success(`${Math.round(r.data.coupon.discount * 100)}% off code applied!`)
                    }

                } else {
                    // setting discount
                    setDiscount(0);
                }
            })
            .catch((e) => {
                console.log(e)
            })
        
    }
    

    const billingCallBack = async () => {
        setShowCard(false)

        await axios.get(account_url, configs)
            .then((r) => {
                store.dispatch(teamSlice.actions.setTeam(r.data))        
            })
            .then(async () => {
                await axios.post(info_url, null, configs)
                    .then((r) => {
                        store.dispatch(billingSlice.actions.setBilling(r.data));
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            })
            .then(() => {
                if (props.callBack){
                    props.callBack(setupData.name)
                }
                setLoading(false);
                toast.success('Billing updated')
            })
            .catch((e) => {
                console.log(e)
            })
        
        return null
    }
       

    const updatePlan = async () => {
        setLoading(true);
        await axios.post(setup_url, setupData, configs)
          .then(() => {
            billingCallBack()
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            toast.error('contact support')
          });
    }


    const formatPrice = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const manageEnterpriseBilling = () => {
        console.log(subUrl)
        if (subUrl){
            window.location.href = subUrl
        }
    }


    if (key === null || pageloading) {
        return <Loader item={'Billing Info'}/>
    }
    
    // can only be created after all pre loading occurs
    const stripePromise = loadStripe(key);

    return(
        <div className="flex-1 bg-white rounded-lg dark:bg-gray-800">
        
            <div className="p-6 sm:p-12 ">
                
                <div className='flex justify-between mb-6'>
                    <div>
                        <h1 id='title' className="text-xl font-semibold text-gray-700 dark:text-gray-200">
                            Current: <span className='py-1 px-2 bg-gray-100 dark:bg-gray-900 rounded font-mono ml-1'>{plan.toUpperCase()}</span>
                        </h1>
                        <div className='mt-2'>
                            <span onClick={() => setViewCoupon(!viewCoupon)} className='hover:text-blue-600 text-blue-500 my-4 text-xs cursor-pointer'>
                                {coupon ? 
                                    'View Coupon'
                                :
                                    'Enter Coupon'
                                }
                            </span>
                            <div>
                            {viewCoupon && 
                                <Input 
                                    className='w-32 rounded h-8'
                                    placeholder='FREE-24'
                                    value={coupon}
                                    onChange={(e) => {
                                        setCoupon(e.target.value)
                                        checkCoupon(e.target.value, true)
                                    }}
                                />
                            }
                            </div>
                        </div>
                    </div>
                </div>

                {plan !== 'enterprise' ?
                    <div>
                        <div className='my-10 w-full'>
                            <div onClick={() => {setBillingInfo('free')}} className={`${plan === 'free' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                                <div className='grid grid-cols-3'>  
                                    <div>
                                        <span className='font-bold text-xs py-1 px-2 rounded-xl text-gray-600 bg-gray-200 dark:text-gray-100 dark:bg-gray-600'>FREE</span>
                                    </div>
                                    <div className='justify-self-center'>
                                        <span className='font-semibold md:text-sm text-xs'>1 Site</span>
                                    </div>
                                    <div className='justify-self-end'>
                                        <div className='flex justify-between'>
                                            <div>
                                                <span className='font-bold md:text-sm text-xs'>$0 /m</span>
                                            </div>
                                            <Tooltip content='view details' classes='my-auto'>
                                            <div 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowFree(!showFree)
                                                }} 
                                                className='ml-2 h-full'
                                            >
                                                <InformationCircleIcon className='h-3 w-3 my-auto'/>
                                            </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                {showFree && 
                                    <div 
                                        className='my-3 p-4 grid grid-cols-2 gap-4 text-xs text-gray-500 dark:text-gray-400 cursor-text'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <div>
                                            <ul className='list-disc ml-2'>
                                                <li className='my-1'>1 Sites</li>
                                                <li className='my-1'>3 Pages per Site</li>
                                                <li className='my-1'>30 Scans & Tests /m</li>
                                                <li className='my-1'>1 Schedules & Alerts</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul className='list-disc ml-2'>
                                                <li className='my-1'>2 weeeks data retention</li>
                                                <li className='my-1'>15 Case runs /m</li>
                                                <li className='my-1'>5 Flow runs /m</li>
                                                <li className='my-1'>Slack Support</li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div onClick={() => {setBillingInfo('cloud')}} className={`${plan === 'cloud' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                                <div className='grid grid-cols-3'>
                                    <div className='my-auto'>
                                        <span className=' font-bold text-xs py-1 px-2 rounded-xl text-blue-600 bg-blue-200 dark:text-blue-100 dark:bg-blue-600'>CLOUD</span>
                                    </div>
                                    <div className='justify-self-center my-auto'>
                                        <div className='flex justify-center'>
                                            <span className='font-semibold md:text-sm text-xs md:my-auto mt-[.35rem]'>&gt; 1 Sites</span>
                                        </div>
                                        
                                    </div>
                                    <div className='justify-self-end'>
                                        <div className='flex justify-between'>
                                            <div>
                                                <span className='font-bold md:text-sm text-xs'>${(19.00 - (19.00*discount)).toFixed(2)} /m</span>
                                            </div>
                                            <Tooltip content='view details' classes='my-auto'>
                                            <div 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowCloud(!showCloud)
                                                }} 
                                                className='ml-2 h-full'
                                            >
                                                <InformationCircleIcon className='h-3 w-3 my-auto'/>
                                            </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                {showCloud && 
                                    <div 
                                        className='my-3 p-4 grid grid-cols-2 gap-4 text-xs text-gray-500 dark:text-gray-400 cursor-text'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <div>
                                            <ul className='list-disc ml-2'>
                                                <li className='my-1'>&gt; 1 Sites</li>
                                                <li className='my-1'>10 Pages per Site</li>
                                                <li className='my-1'>10 Schedules & Alerts</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul className='list-disc ml-2'>
                                                <li className='my-1'>6 month data retention</li>
                                                <li className='my-1'>$0.10 per additional Task /m</li>
                                                <li className='my-1'>Slack Support</li>
                                                <li className='my-1'>Email Support</li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div onClick={() => {setBillingInfo('selfhost')}} className={`${plan === 'selfhost' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                                <div className='grid grid-cols-3'>
                                    <div>
                                        <span className='font-bold text-xs py-1 px-2 rounded-xl text-teal-700 bg-teal-200 dark:text-teal-100 dark:bg-teal-600'>SELFHOST</span>
                                    </div>
                                    <div className='justify-self-center'>
                                        <span className='font-semibold md:text-sm text-xs'>&#8734; Sites</span>
                                    </div>
                                    <div className='justify-self-end'>
                                        <div className='flex justify-between'>
                                            <div>
                                                {interval === 'month' ?
                                                    <span className='font-bold md:text-sm text-xs'>${(1000-(1000*discount)).toFixed(2)} /m</span>
                                                :
                                                    <span className='font-bold md:text-sm text-xs'>${(833-(833*discount)).toFixed(2)} /m</span>
                                                }
                                            </div>
                                            <Tooltip content='view details' classes='my-auto'>
                                            <div 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowSelfHost(!showSelfHost)
                                                }} 
                                                className='ml-2 h-full'
                                            >
                                                <InformationCircleIcon className='h-3 w-3 my-auto'/>
                                            </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                {showSelfHost && 
                                    <div 
                                        className='my-3 p-4 grid grid-cols-2 gap-4 text-xs text-gray-500 dark:text-gray-400 cursor-text'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <div>
                                            <ul className='list-disc ml-2'>
                                                <li className='my-1'>&#8734; Sites</li>
                                                <li className='my-1'>&#8734; Pages per Site</li>
                                                <li className='my-1'>&#8734; Scans & Tests /m</li>
                                                <li className='my-1'>&#8734; Schedules & Alerts</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <ul className='list-disc ml-2'>
                                                <li className='my-1'>&#8734; Data retention</li>
                                                <li className='my-1'>&#8734; Case runs /m</li>
                                                <li className='my-1'>&#8734; Flow runs /m</li>
                                                <li className='my-1'>Premium Engineer Support</li>
                                            </ul>
                                        </div>
                                        <a href='https://docs.cursion.dev/guides/selfhost' 
                                            target='_blank'
                                            className='flex justify-start text-gray-600 dark:text-gray-300'
                                        >
                                            <span className='underline decoration-blue-600'>Installation instructions</span>
                                            <ExternalLinkIcon className='mt-[.2rem] ml-1 h-3'/>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>

                        
                        {(info.card && !showCard && (plan !== 'free')) ? 
                            <div className='flex justify-between mt-3'>
                                {!showCard && 
                                    <div className='mt-3'>
                                        <span 
                                            onClick={() => {
                                                setShowCard(true)
                                                setType('card')
                                            }} 
                                            className='hover:text-blue-600 text-blue-500 my-4 text-xs cursor-pointer'
                                        >
                                            Update Card
                                        </span>
                                    </div>
                                }
                                <div>
                                    <Button layout="outline" size="small" onClick={updatePlan} disabled={loading || plan === info.plan.name}>
                                        <Spinner display={loading}/>
                                        Update
                                    </Button>
                                </div>
                            </div>
                        :
                            <>
                            {plan !== 'free' && 

                            <>
                                <Elements stripe={stripePromise}>
                                    <BillingForm 
                                        setupData={setupData} 
                                        callBack={billingCallBack} 
                                        type={type} 
                                        hideCardCallBack={() => {
                                            setType('initial')
                                            setShowCard(false)
                                        }}
                                    />
                                </Elements>
                            </>
                                
                            }
                            </>
                            
                        }

                    </div>
                :
                    
                    <div className='mt-8'>
                        <Button 
                            iconRight={ExternalLinkIcon} layout='outline'
                            onClick={manageEnterpriseBilling}
                        >
                            Manage Billing
                        </Button>
                    </div>

                }
                

            </div>

        </div>

    )
}




export default BillingPlanCard