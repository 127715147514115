import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { Input, Button } from '@windmill/react-ui'
import { CheckIcon, ArrowRightIcon, ArrowNarrowRightIcon, ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { PlayIcon } from '@heroicons/react/solid'
import VideoCoverDark from '../assets/img/video-cover-dark.webp'
import VideoCoverLight from '../assets/img/video-cover-light.webp'
import axios from "axios";
import Spinner from '../components/Buttons/Spinner'
import Gear from '../components/Buttons/Gear';
import store from '../store';
import toast from 'react-hot-toast';
import Loader from '../components/Buttons/Loader';





function Onboard() {


    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [siteUrl, setSiteUrl] = useState('')
    const [site, setSite] = useState(null)
    const [progress, setProgress] = useState(10)
    const [step, setStep] = useState(0)
    const [showVideo, setShowVideo] = useState(false)
    const member = store.getState().member.member
    const team = store.getState().team.team
    const token = store.getState().auth.token
    const headers = {'Authorization': `Bearer ${token}`}
    const configs = {headers: headers, skipAuthRefresh: true}

    const BACKEND_URL = process.env.REACT_APP_SERVER_URL || window.env.REACT_APP_SERVER_URL;
    const site_url = `${BACKEND_URL}/v1/ops/site`
    const page_url = `${BACKEND_URL}/v1/ops/page`
    const process_url = `${BACKEND_URL}/v1/ops/process`
    const navigate = useNavigate()



    const checkMember = async () => {
        if (!token){
            console.log(window.location.href)
            localStorage.setItem("next", window.location.href);
            navigate('/register')
        }
        else if (member.type !== 'admin'){
            console.log('member not admin')
            navigate('/')
        }else {
            await axios.get(site_url, configs)
                .then(async (r) => {
                    if (r.data.count > 1){
                        console.log('account has more than 1 site')
                        navigate('/')
                    }
                    else if (r.data.count === 1 ){
                        // saving site state
                        setSite(r.data.results[0])
                        
                        // check onboarding progress
                        checkProgress(r.data.results[0])
                            .then((onboarded) => {
                                if (onboarded){
                                    // forward to homepage
                                    console.log('site onboarded already')
                                    navigate('/')
                                }else{
                                    setReady(true)
                                    setStep(2)
                                }
                        })
                        
                        
                    } else if ((team.type === 'selfhost' ) || (team.type === 'admin')) {
                        // forward to homepage
                        console.log(`account is ${team.type} - done onboarding`)
                        navigate('/')
                    }else {
                        setReady(true)
                        setStep(0)
                    }
                }).catch((e) => {
                    console.log(e)
                    toast.error('Contact support')
                    setReady(false)
                })
        }
        
    }


    const checkProgress = async (_site) => {
        // get passed site or site from State
        let thisSite = _site || site

        // set default progress
        let prog = progress

        // check for progress
        if (thisSite) {
            await axios.get(`${site_url}/${thisSite.id}`, configs)
                
                // check for crawl completion
                .then((r) => {
                    if (r.data.time_crawl_completed && prog < 25){
                        setProgress(25)
                        prog = 25
                    }
                    console.log(`progress set to -> ${prog}`)
                })

                // check for page scans & case generation
                .then(async () => {
                    if (prog >= 25) {
                        
                        // get current pages 
                        await axios.get(`${page_url}?site_id=${thisSite.id}`, configs)
                            
                            // calc prog from scan completion
                            .then(async (r) => {
                                let precent_complete = 0
                                let pages = r.data.results
                                let count = (50 / r.data.count)
                                for (let i in pages){
                                    if (pages[i].info.latest_scan.time_completed){
                                        console.log(`adding ${count} to ${precent_complete}`)
                                        precent_complete += count
                                    }
                                }
                                
                                // check for case gen completion
                                await axios.get(`${process_url}?type=case.generate`, configs)
                                    .then((r) => {
                                        if (r.data.results.length > 0){
                                            let proc = r.data.results[0]
                                            precent_complete += (25 * (proc.progress / 100))
                                            prog = (25 + precent_complete)
                                            setProgress(prog)
                                            console.log(`progress from case gen & scans -> ${prog}%`)
                                        } else{
                                            precent_complete += 25
                                            prog = (25 + precent_complete)
                                            setProgress(prog)
                                            console.log('no running processes for case.generate')
                                            console.log(`progress from case gen & scans -> ${prog}%`)
                                        }
                                        
                                    })
                                    .catch((e) => {
                                        console.log(e)
                                    })

                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    } 
                    else {
                        // check if prog === 100
                        console.log(`final prog -> ${prog}`)
                        if (prog >= 100){
                            return true
                        } else {
                            return false
                        }
                    }
                })
                .then(() => {
                    // check if prog === 100
                    console.log(`final prog -> ${prog}`)
                    if (prog >= 100){
                        return true
                    } else {
                        return false
                    }
                })
        } 
        else{
            // check if prog === 100
            console.log(`final prog -> ${prog}`)
            if (prog >= 100){
                return true
            } else {
                return false
            }
        }

        // check if prog === 100
        console.log(`final prog -> ${prog}`)
        if (prog >= 100){
            return true
        } else {
            return false
        }
        
    }


    useEffect(() => {
        checkMember()
    }, [])


    useEffect(() => {
        if (progress === 100 && step === 2){
            // display next step
            console.log('site fully onboarded')
            setStep(3)
        }
    }, [progress, step])


    useEffect(() => {
        const interval = setInterval(() => {
            console.log('triggered interval')
            checkProgress();
        }, 5000);
        return () => {
          clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [site, progress]);


    const handleKeyDownSite = (e) => {
        if (e.key === 'Enter') {
            addSite()
        }
    }


    const addSite = async () => {
        
        setLoading(true);
        const data = {
            "site_url": siteUrl,
            "onboarding": true,
        }

        if(siteUrl === ''){
            setLoading(false);
            toast.error('enter a site url');
        } else if (!siteUrl.startsWith('https://') && !siteUrl.startsWith('http://')){
            setLoading(false);
            toast.error('please use http');
        }else{
            await axios.post(`${site_url}`, data, configs)
                .then((r) => {
                    console.log(r.data)
                    setSite(r.data)
                    setProgress(10)
                    setLoading(false);
                    setSiteUrl('');
                    toast.success('Site added');
                    setStep(step+1)
                    checkProgress();
                }).catch((e) => {
                    if (e.response) {
                    toast.error(e.response.data.reason)
                    setLoading(false);
                    }
                })
        }


    }


    const checkMark = () => {
        return (
            <div className={`rounded-full bg-green-300 text-green-700 p-1 h-5 w-5`}>
                <CheckIcon className='h-3 w-3'/>
            </div>
        )
    }


    const playVideo = () => {
        let video = document.getElementById("welcome-video"); 
        if (video.paused) {
            video.play(); 
            setShowVideo(true)
        }else {
            video.pause();
            setShowVideo(false)
        } 

    }


    const getVideoLink = () => {
        let video = 'https://storage-scanerr.sfo3.cdn.digitaloceanspaces.com/static/videos/Agency%20Owner.mp4'
        let type = team.info.survey[3].answer 
        if (type.length === 0){
            type = 'Project Manager'
        }
        // decide on video
        if (type.includes('Owner')){
           video = 'https://storage-scanerr.sfo3.cdn.digitaloceanspaces.com/static/videos/Agency%20Owner.mp4'
        }
        if (type.includes('Manager')) {
            video = 'https://storage-scanerr.sfo3.cdn.digitaloceanspaces.com/static/videos/Project%20Manager.mp4'
        }
        if (type.includes('Developer')) {
            video = 'https://storage-scanerr.sfo3.cdn.digitaloceanspaces.com/static/videos/Developer.mp4'
        }
        return video
    }


    if (!ready){
        return (
            <div className='flex h-screen bg-gray-100 dark:bg-gray-900'>
                <Loader item={'Info'}/>
            </div>
        )
    }
    


    return(
        <div className="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
            { ready && 
                <div className={`flex-1 h-full ${step === 1 ? 'max-w-4xl' : 'max-w-xl'} mx-auto bg-white rounded-lg shadow-xl dark:bg-gray-800`}>

                    {/* add-site section*/}
                    {(step === 0) &&
                        <div className="p-6 sm:p-12">
                            <h1 id='title' className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                                Add Your First Site
                            </h1>
                            <div>
                                <Input 
                                    className="rounded-md p-2 mt-1" 
                                    onKeyDown={handleKeyDownSite}
                                    type="text"
                                    name='site-url'
                                    placeholder="https://example.com" 
                                    value={siteUrl}
                                    onChange={e => setSiteUrl(e.target.value)}/>
                                <Button 
                                    type="submit" 
                                    block 
                                    className={`mt-4 ${(siteUrl.length > 0 && !loading)? 'cursor-pointer' : 'cursor-not-allowed'}`} 
                                    onClick={addSite} 
                                    disabled={siteUrl.length <= 0 || loading}
                                >
                                    <Spinner display={loading}/>
                                    Add Site
                                </Button>
                            </div>
                        </div>
                    }

                    {/* video section */}
                    {(step === 1) &&
                        <div className='max-w-4xl mx-auto p-6'>

                            <div className='flex justify-center'>
                                <h1 id='title' className="rounded-lg py-3 px-5 bg-gray-100 dark:bg-gray-900 text-center mb-8 text-xl font-semibold text-gray-700 dark:text-gray-200">
                                    Some features we think you'll like.
                                </h1>
                            </div>
                            
                            <video controls={true} className={`rounded-lg ${!showVideo && 'hidden'}`} id='welcome-video' name="welcome video" preload="true" onEnded={() => setShowVideo(false)} onPause={() => setShowVideo(false)}>
                                <source src={getVideoLink()} type="video/mp4"/>
                            </video>

                            <div onClick={playVideo} className={`relative cursor-pointer  ${showVideo ? 'hidden' : ''}`}>
                                <div className='absolute top-[45%] md:left-[45%] left-[40%]'>
                                    <div className='relative'>
                                        <PlayIcon className='absolute inline-flex animate-ping dark:text-blue-600 dark:bg-white text-white bg-blue-600 h-20 w-20 rounded-full'/>
                                        <PlayIcon className='relative inline-flex dark:text-blue-600 dark:bg-white text-white bg-blue-600 h-20 w-20 rounded-full'/>
                                    </div>
                                </div>
                                <img
                                    className='rounded-lg w-full dark:hidden flex'
                                    src={VideoCoverLight}
                                    alt="Demo Video"
                                />
                                <img
                                    className='rounded-lg w-full hidden dark:flex'
                                    src={VideoCoverDark}
                                    alt="Demo Video"
                                />
                            </div>

                            <div className='flex justify-end'>
                                <div className='flex mt-6 hover:text-blue-600 text-blue-500 text-sm text-right cursor-pointer' onClick={() => {setStep(step+1)}}>
                                    <div><p className="mr-1 mt-1">next</p></div>
                                    <ArrowNarrowRightIcon className='w-7'/>
                                </div>
                                {/* <span className='' onClick={() => }>next</span> */}
                            </div>
                            
                        </div>
                    }

                    {/* explaination section */}
                    {(step === 2) &&
                        <div className="p-6 sm:p-12">
                            <div className='flex justify-start w-full'>
                                <span className='text-2xl'>🚀</span>
                                <h1 id='title' className="ml-4 mb-4 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                                    Off To The Races!
                                </h1>
                            </div>
                           
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div className="bg-blue-600 h-2.5 rounded-full transition-all ease-out duration-1000" style={{width: `${progress}%`}}></div>
                            </div>

                            <div className='mt-12 mx-auto text-left'>
                                <h3 className='dark:text-gray-300 text-gray-800 font-semibold text-xl mb-2 text-center'>What's happening now:</h3>
                                <div className='flex justify-center'>
                                    <div>
                                        <ol className='font-normal text-sm mb-6'>
                                            <li>
                                                <div className='flex justify-start my-1'>
                                                    {progress === 10 && <Gear margins={'-ml-[.10rem] -mr-[.15rem]'}/>}
                                                    {progress > 10 && checkMark()} 
                                                    <span className='dark:text-gray-300 text-gray-800 ml-3'>
                                                        Crawling your Site for Pages
                                                    </span>
                                                </div>
                                            </li>
                                            <li >
                                                <div className='flex justify-start my-1'>
                                                    {progress === 25 && <Gear margins={'-ml-[.10rem] -mr-[.15rem]'}/>}
                                                    {progress > 25 && checkMark()} 
                                                    <span className={progress >= 25 ? `dark:text-gray-300 text-gray-800 ml-3` : `dark:text-gray-500 text-gray-400 ml-3`}>
                                                        Saving the Pages in Cursion
                                                    </span>
                                                </div>
                                            </li>
                                            <li >
                                                <div className='flex justify-start my-1'>
                                                    {(progress > 25 && progress < 75) && <Gear margins={'-ml-[.10rem] -mr-[.15rem]'}/>}
                                                    {progress >= 75 && checkMark()} 
                                                    <span className={progress > 25 ? `dark:text-gray-300 text-gray-800 ml-3` : `dark:text-gray-500 text-gray-400 ml-3`}>
                                                        Creating Scans for each Page
                                                    </span>
                                                </div>
                                            </li>
                                            <li >
                                                <div className='flex justify-start my-1'>
                                                    {(progress >= 75 && progress < 100) && <Gear margins={'-ml-[.10rem] -mr-[.15rem]'}/>}
                                                    {progress === 100 && checkMark()} 
                                                    <span className={progress > 75 ? `dark:text-gray-300 text-gray-800 ml-3` : `dark:text-gray-500 text-gray-400 ml-3`}>
                                                        Generating Cases for your Site
                                                    </span>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>

                                {progress !== 100 &&
                                    <div className='flex justify-between'>
                                        <div 
                                            className='cursor-pointer text-sm hover:text-blue-600 text-blue-500 max-w-sm'
                                            onClick={() => {
                                                setStep(step-1)  
                                            }}
                                        >
                                            <div className='flex mt-6'>
                                                <ArrowNarrowLeftIcon className='w-7'/>
                                                <div><p className="ml-1 mt-1">back</p></div>
                                            </div>
                                        </div>
                                        <div 
                                            className='cursor-pointer text-sm hover:text-blue-600 text-blue-500 max-w-sm'
                                            onClick={() => navigate('/')}
                                        >
                                            <div className='flex mt-6'>
                                                <div><p className="mr-1 mt-1">skip</p></div>
                                                <ArrowNarrowRightIcon className='w-7'/>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                    {/* Onboard complete section */}
                    {(step === 3) &&
                        <div className="p-6 sm:p-12">

                            <div className='flex justify-start w-full'>
                                <span className=''>
                                    <div className={`rounded-full bg-green-300 text-green-700 p-1 h-7 w-7`}>
                                        <CheckIcon className='h-5 w-5'/>
                                    </div>
                                </span>
                                <h1 id='title' className="ml-4 mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                                   All Done Here.
                                </h1>
                            </div>

                            <p className='text-lg text-gray-600 dark:text-gray-300'>
                                Your Site has been successfully onboarded to the Cursion Platform. 
                                If you need anything, we're here to help. 
                                Happy Hacking! <span>💻</span>
                            </p>
                             
                            <div 
                                className="flex justify-end">
                                <Button
                                    size='small'
                                    onClick={() => navigate(`/`)}
                                >
                                    View Site
                                    <ArrowRightIcon className='ml-1 h-5'/>
                                </Button>
                            </div>
                        </div>
                    }

                </div>
            }
        </div>
    )
}

export default Onboard